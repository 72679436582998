import config from 'src/config';

// TODO: figure out how to set normal webpack config information for dynamic imports
export default () => {
  const isDev = process.env.NODE_ENV === 'development';
  // eslint-disable-next-line
  __webpack_public_path__ = isDev
    ? 'http://localhost:3001/'
    : `${config.API_URL.replace('/api/v1', '')}/js/dist/`;
};
