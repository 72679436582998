// This file allows us to dynamically import async raw js modules/bundles (non-react) from BE (in JsTwig.php)
// via the twig rendering helper function "js" with the second param, `lazy`, being `true` e.g: {{ js('byron/byron', true) }}.
// When not passing the second param as true, we are using the backend/public/js/dist/stats.json file to map bundle names to js modules
// which only works for the 'named' bundles that have been configured via the webpack config inside the backend/js/.plzrc.js
import setWebpackPath from 'utils/set-webpack-async-modules-public-path';
setWebpackPath();

window.__TwigJs = window.__TwigJs || {};

function dynamicImport(path) {
  return import(`./personas/${path}.js`);
}

window.__TwigJs.dynamicImport = dynamicImport;
